<template>
  <div class="p-2">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :pagination-options="{
        enabled: true,
        perPage: 100,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'ad_soyad'">
          <router-link
            :to="{
              name: 'uye-guncelle',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="d-block text-dark font-weight-bold">{{ props.row.ad_soyad }}</span>
            <span class="d-block">{{ props.row.e_mail }}</span>
            <span class="d-block text-info">{{ props.row.urun['tr'].baslik | str_limit(25) }}</span>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'yorum'">
          <span
            class="rounded-0 bg-info text-light p-1 d-block"
            v-b-tooltip.hover.top="'Devamını okumak için lütfen üzerine tıklayınız.'"
            @click="handlerYorumDetay(props.row)"
          >
            {{ props.row.mesaj | str_limit(100) }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'tarih'">
          {{ props.row.save_date | momentFull }}
        </span>
        <span v-else-if="props.column.field === 'puan'">
          <h5>
            <b-badge :variant="puanColor(props.row.puan)">{{ props.row.puan }}</b-badge>
          </h5>
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.statu"
            switch
            @change="handlerStatu(props.row.k_no, $event)"
            size="lg"
          />
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button variant="danger" class="rounded-0" @click="handlerRemove(props.row.k_no)">
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center my-3">
            <v-select
              v-model="filter.pageSize"
              :options="perPageOptions"
              :clearable="false"
              style="width: 200px"
              class="invoice-filter-select"
              @input="(value) => handlerPageSizeChange(value)"
            />
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="count"
              :per-page="filter.pageSize"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="my-3"
              @input="(value) => handlerPageChange(value)"
            >
              <template #prev-text>
                <i class="fad fa-chevron-left" />
              </template>
              <template #next-text>
                <i class="fad fa-chevron-right" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <yorum-detay :openModal="openModal" :updateData="updateData" @closeModal="closeModal = $event" />
  </div>
</template>
<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import YorumDetay from './yorumDetay.vue';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import BTableCard from '@/components/cards/BTableCard.vue';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
export default defineComponent({
  components: {
    vSelect,
    BTableCard,
    VueGoodTable,
    BTableCard,
    YorumDetay,
  },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.openModal = ref(false);
    expo.closeModal = ref(false);
    expo.updateData = ref({});
    expo.perPageOptions = ref([20, 40, 50, 80, 100]);
    expo.count = ref(0);
    expo.filter = ref({
      page: 0,
      pageSize: 20,
    });
    expo.columns = ref([
      {
        label: 'İsim ( Ünvan )',
        field: 'ad_soyad',
        width: '18%',
      },
      {
        label: 'Yorum',
        field: 'yorum',
      },
      {
        label: 'Tarih',
        field: 'tarih',
        width: '12%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Puan',
        field: 'puan',
        width: '5%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Statü',
        field: 'statu',
        width: '5%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]);
    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      await store.dispatch('yorumListele', expo.filter.value).then((res) => {
        if (res.data.success) {
          expo.rows.value = store.getters.getYorumlar;
          expo.count.value = res.data.count;
          context.emit('total', expo.count.value);
        }
      });
      context.emit('show', false);
    };
    handlerFetchAllData();

    expo.puanColor = computed(() => {
      const statusColor = {
        1: 'danger',
        2: 'warning',
        3: 'info',
        4: 'primary',
        5: 'success',
      };

      return (puan) => statusColor[puan];
    });

    expo.handlerYorumDetay = (data) => {
      expo.updateData.value = data;
      expo.openModal.value = true;
    };

    expo.handlerPageSizeChange = (event) => {
      expo.filter.value.pageSize = event;
      handlerFetchAllData();
    };

    expo.handlerPageChange = (event) => {
      expo.filter.value.page = event;
      expo.filter.value.page = expo.filter.value.page - 1;
      handlerFetchAllData();
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('yorumSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerStatu = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns.post('/yorumlar/yorum-statu-guncelle', { k_no, statu: event }).then((res) => {
          if (res.data.success === true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          }
        });
      }
    };

    watch(
      expo.closeModal,
      (val) => {
        expo.openModal.value = false;
        expo.closeModal.value = false;
        expo.updateData.value = {};
      },
      { deep: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
